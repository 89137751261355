import { inject, Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomCookieService {
  private apiService = inject(ApiService);
  private cookieService = inject(CookieService);

  private subdomainCookieName = 'accessTokenSubdomain';

  /**
   *  Cookie expiration date in milliseconds (2 hours - like the JWT expiration)
   */
  cookieExpiration = 2 * 60 * 60 * 1000;

  /**
   * Sets the cookie with the user session for the root domain
   * @param token The session token of the user
   * @param subdomain The client subdomain
   */
  public createUserSessionCookie(token: string, subdomain: string): void {
    const rootDomain = this.apiService.getDomainRoot();
    const cookieOptions: CookieOptions = {
      expires: new Date(new Date().getTime() + this.cookieExpiration),
      path: '/',
      domain: rootDomain,
      secure: true,
      sameSite: 'Lax'
    };
    this.cookieService.set(environment.accessTokenStorage, token, cookieOptions);
    this.cookieService.set(this.subdomainCookieName, subdomain, cookieOptions);
  }

  public deleteUserSessionCookie(): void {
    this.cookieService.delete(environment.accessTokenStorage);
  }
}
