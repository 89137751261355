import { inject, Injectable } from '@angular/core';
import { EnvironmentInterface } from 'src/definitions/environment.interface';
import { environment } from 'src/environments/environment';

import { DomainConfigService } from './domain-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private domainConfigService = inject(DomainConfigService);

  /**
   * Domain
   */
  private domain: URL;

  /**
   * Line domain
   */
  private educaDomain!: URL;

  /**
   * Security domain
   */
  private securityDomain!: URL;

  /**
   * Constructor
   * @param {any} environment Environment config
   */
  private environment: EnvironmentInterface;

  constructor() {
    const currentUrl = new URL(window.location.href);
    this.environment = environment;
    this.domain = new URL(this.environment.apiPath, `${currentUrl.protocol}//${currentUrl.hostname}`);
    if (this.environment.apiPort) {
      this.domain.port = this.environment.apiPort.toString(10);
    }
    this.buildEducaUniversityApiUrl();

    this.securityDomain = new URL(
      this.environment.apiPath,
      `${currentUrl.protocol}//${this.environment.apiSecurityDomain}`
    );

    if (this.environment.apiPort) {
      this.securityDomain.port = this.environment.apiPort.toString(10);
    }
  }

  public getDomain(): string {
    return this.domain.hostname;
  }

  public getSubdomain(): string {
    return this.domainConfigService.getConfigForSubdomain(this.getDomain()).subdomain;
  }

  public getApiUrl(): string {
    const subdomain = this.domainConfigService.getConfigForSubdomain(this.getDomain()).subdomain;
    if (environment.name === 'local') {
      return `http://${subdomain}.localhost:5000/${environment.apiPath}`;
    }

    if (environment.name === 'dev' || environment.name === 'uat') {
      return `https://${subdomain}.euro${environment.name}.bi4dev.com/${environment.apiPath}`;
    }

    return `https://${subdomain}.app.euroinnova.edu.es/${environment.apiPath}`;
  }

  private buildEducaUniversityApiUrl(): void {
    const protocol = environment.httpSecure ? 'https' : 'http';
    let url = `${protocol}://${environment.universitySecurityApiDomain}`;
    if (environment.apiEducaPort) {
      url = url + `:${environment.apiEducaPort.toString()}`;
    }
    this.educaDomain = new URL(this.environment.apiPath, url);
  }

  public getEducaUniversityApiUrl(): string {
    return this.educaDomain.toString();
  }

  public getApiHostname(): string {
    const parts = this.domain.hostname.toString().split('.');

    return parts[0];
  }

  public getDomainRoot(): string {
    const parts = this.domain.hostname.split('.');
    if (parts.length >= 2) {
      return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }

    return this.domain.hostname;
  }

  public getSecurityUrl(): string {
    return this.securityDomain.toString();
  }
}
