import { autoserializeAs } from 'dcerialize';

import { PaymentStatus } from '../definitions/payment-status.enum';

/**
 * Class that represents an enrolment payment
 */
export class Payment {
  /**
   * Payment amount
   */
  @autoserializeAs(() => Number) amount: number;

  /**
   * Payment date
   */
  @autoserializeAs(() => Date) paymentDate: Date;

  /**
   * Payment status
   */
  @autoserializeAs(() => String) status: PaymentStatus;

  /**
   * Whether it's paid or not
   */
  @autoserializeAs(() => Boolean) paid: boolean;

  constructor(amount: number, paymentDate: Date, status: PaymentStatus, paid = false) {
    this.amount = amount;
    this.paymentDate = paymentDate;
    this.status = status;
    this.paid = paid;
  }
}

/**
 * Class that represents the price of a subscription
 */
export class SubscriptionPrice {
  /**
   * Subscription price
   */
  @autoserializeAs(() => String) price?: string;

  /**
   * Subscription currency
   */
  @autoserializeAs(() => String) currency?: string;

  /**
   * Subscription interval
   */
  @autoserializeAs(() => String) interval?: string;

  /**
   * Subscription interval count
   */
  @autoserializeAs(() => Number) intervalCount?: number;

  /**
   * Subscription price discounted
   */
  @autoserializeAs(() => String, 'price_discounted') discountedPrice: string;

  /**
   *
   * @param price
   * @param currency
   * @param interval
   * @param intervalCount
   * @param discountedPrice
   */

  constructor(price: string, currency: string, interval: string, intervalCount: number, discountedPrice: string) {
    this.price = price;
    this.currency = currency;
    this.interval = interval;
    this.intervalCount = intervalCount;
    this.discountedPrice = discountedPrice;
  }
}
